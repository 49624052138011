import { useEffect, useState, type ChangeEvent } from 'react';
import styled from '@grebban/style-system-react';
import validateEmail from '@sportson/core-web/utils/validateEmail';
import Column from '@sportson/core-web/App/layouts/Column';
import Background from '@sportson/core-web/components/Background';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { type WordpressNewsletterForm } from 'libs/wordpress/types/contentSystem/groups';
import { type TransformHeadingOutput } from 'libs/wordpress/utils/transformHeading';
import Text from 'components/Text';
import Checkbox from 'components/inputs/Checkbox';
import EmailInput from 'components/inputs/EmailInput';
import Subscribe from '@sportson/core-web/libs/GrebbCommerceAPI/Newsletter/Subscribe';
import { SubmitButton } from 'components/SubmitButton';
import { uniqueId } from '@sportson/core-web/utils/uniqueId';

const ContentWrapper = styled('div')`
    z-index: 1;
`;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    border-radius: 4px;
    overflow: hidden;
`;

const Form = styled('form')`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    gap: 16px;

    > button {
        margin-top: 8px;
    }
`;

const BackgroundWrapper = styled('div')`
    position: absolute;
    display: flex;
    inset: 0;
    mix-blend-mode: multiply;

    @supports not (inset: 0) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

export type NewsletterComponentAlignmentProps = 'flex-start' | 'center' | 'flex-end';
export interface IsMobile {
    isMobile: boolean;
}
export interface NewsletterComponentProps extends IsMobile {
    heading?: TransformHeadingOutput;
    tag: string;
    contentHorizontalAlignment?: NewsletterComponentAlignmentProps;
    textColor?: CssVariable<CssColorKey>;
    newsletterForm: WordpressNewsletterForm;
    backgroundColor?: BackgroundColorProps;
    backgroundImage?: BackgroundImageProps;
    backgroundVideo?: BackgroundVideoProps;
}

export const NewsletterComponent = ({
    tag,
    newsletterForm,
    heading,
    contentHorizontalAlignment,
    textColor = 'var(--color-base-white)',
    isMobile,
    backgroundColor,
    backgroundImage,
    backgroundVideo,
}: NewsletterComponentProps) => {
    const is2Column = false; // TODO: Add logic for 2 column layout
    const noBackground = backgroundColor?.backgroundColor === undefined && !backgroundImage && !backgroundVideo;

    const [formState, setFormState] = useState({
        emailValid: false,
        emailError: false,
        consent: false,
        consentError: false,
        success: false,
        successMessage: newsletterForm?.form?.successMessage,
        errorMessage: newsletterForm?.form?.errorMessage,
        loading: false,
        isDisabled: true,
        responseError: false,
    });

    const handleConsentChange = (value: { checked: boolean }) => {
        value.checked
            ? setFormState({ ...formState, consent: value.checked, consentError: true })
            : setFormState({ ...formState, consent: value.checked, consentError: false });
    };

    const validateInput = (e: ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        if (email) {
            const emailValid = validateEmail(email);
            setFormState({ ...formState, emailValid, emailError: !emailValid });
        }
    };

    const submitForm = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setFormState({ ...formState, loading: true });

        const formData = new FormData(ev.target);
        const formProps = Object.fromEntries(formData);

        const data = {
            email: '',
            privacyPolicyConsent: false,
        };

        Object.keys(formProps).forEach((key) => {
            const inputType = key;
            const value = formProps[key];
            if (inputType === 'email') {
                data.email = value.toString();
            } else if (inputType === 'checkbox' && value === 'on') {
                data.privacyPolicyConsent = true;
            }
        });

        const submitData = {
            email: data.email,
        };

        if (data.email && data.privacyPolicyConsent) {
            try {
                const res = await Subscribe(submitData);

                if (res.data) {
                    setFormState({ ...formState, success: true, loading: false });

                    setTimeout(() => {
                        ev.target.reset();
                        setFormState({ ...formState, consent: false, consentError: false });
                    }, 5000);
                } else {
                    console.log('Response Error!');
                    setFormState({ ...formState, success: false, loading: false, responseError: true });

                    setTimeout(() => {
                        ev.target.reset();
                        setFormState({ ...formState, responseError: false });
                    }, 5000);
                }
            } catch (error) {
                console.log('Request Error!');
                console.log(formState.errorMessage, error);
                setFormState({ ...formState, success: false, loading: false });
            }
        }
    };

    useEffect(() => {
        if (formState.success) {
            setFormState({ ...formState, isDisabled: true });

            setTimeout(() => {
                setFormState({ ...formState, success: false });
            }, 5000);
        } else if (formState.emailValid && formState.consent) {
            setFormState({ ...formState, isDisabled: false });
        } else {
            setFormState({ ...formState, isDisabled: true });
        }
    }, [formState.emailValid, formState.consent, formState.success]);

    return (
        <Wrapper
            backgroundColor={noBackground && 'var(--color-base-black)'}
            color={textColor}
            m={is2Column ? '0' : ['0 12px', null, null, null, '40px']}
            p={['40px 12px', null, null, null, '40px 40px 64px']}
        >
            {!noBackground && (
                <BackgroundWrapper>
                    <Background
                        backgroundVideo={backgroundVideo}
                        backgroundImage={backgroundImage}
                        backgroundColor={backgroundColor}
                    />
                </BackgroundWrapper>
            )}

            {tag && (
                <Text zIndex="1" $as="p" color="var(--color-brand-yellow)" typography="UI/12_100_0_450_uppercase">
                    {tag}
                </Text>
            )}

            <ContentWrapper
                display="flex"
                flexDirection={isMobile || is2Column ? 'column' : 'row'}
                gap={['40px', null, null, null, '128px']}
            >
                <Column width={is2Column ? '100%' : ['100%', null, null, '525px']}>
                    {heading?.text && (
                        <Wysiwyg
                            data={heading.text}
                            TextComponent={(props) => (
                                <Text
                                    as={heading?.type || 'h3'}
                                    textAlign={contentHorizontalAlignment || 'left'}
                                    color={heading.color || textColor}
                                    typography={['Content/20_130_0_450', null, null, null, 'Content/32_120_0_500']}
                                    {...props}
                                />
                            )}
                        />
                    )}
                </Column>

                <Column width={is2Column ? '100%' : ['100%', null, null, null, 'calc(100% - 525px)']}>
                    <Form onSubmit={submitForm} method="post">
                        {/* TODO: Fix regex, allowing incomplete emails (eg: test@test) not requiring the last dot */}
                        <EmailInput
                            required
                            name="email"
                            label={newsletterForm?.emailInput?.label}
                            placeholder={newsletterForm?.emailInput?.placeholder}
                            onBlur={validateInput}
                            customErrors={{
                                valueMissing: newsletterForm?.emailInput?.errorMessage,
                                patternMismatch: newsletterForm?.emailInput?.errorMessage,
                                typeMismatch: newsletterForm?.emailInput?.errorMessage,
                            }}
                        />
                        <Checkbox
                            required
                            name="checkbox"
                            id={uniqueId('checkbox')}
                            label={newsletterForm?.privacyCheckboxInput?.text}
                            checked={formState.consent}
                            onChange={(e) => handleConsentChange(e.target)}
                            customErrors={{
                                valueMissing: newsletterForm?.privacyCheckboxInput?.errorMessage,
                            }}
                        />
                        <SubmitButton
                            theme="primary"
                            disabled={formState.isDisabled}
                            typography="UI/16_100_0_450"
                            gap="6px"
                        >
                            {formState.success
                                ? formState.successMessage
                                : formState.responseError
                                  ? formState.errorMessage
                                  : newsletterForm.button.label}
                        </SubmitButton>
                    </Form>
                </Column>
            </ContentWrapper>
        </Wrapper>
    );
};
