import React from 'react';
import styled from '@grebban/style-system-react';
import PropTypes from 'prop-types';

const StyledWrapper = styled('div')`
    width: 100%;
`;

const FullWidth = ({ children, ...rest }) => <StyledWrapper {...rest}>{children}</StyledWrapper>;

FullWidth.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FullWidth;
