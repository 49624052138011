import styled from '@grebban/style-system-react';
import PropTypes from 'prop-types';
import React from 'react';
import responsiveBreakpointsStyle from '@sportson/core-web/utils/responsiveBreakpointsStyle';

const DefaultAspectWrapper = styled('div')`
    ${({ ratios }) => responsiveBreakpointsStyle(ratios, 'aspect-ratio: {value};')}
`;

const FlexibleAspectWrapper = styled('div')`
    // Style for flexible AspectWrapper
    // This style will allow the component to have a fixed ratio, but if the content doesn't fit that ratio it will allow the element to grow

    display: flex;
    align-items: stretch;

    // ::before and ::after will make the wrapper have a ratio, but if the content need more space the height is flexible
    &::before {
        content: '';
        float: left;
        ${({ ratios }) => responsiveBreakpointsStyle(ratios, 'padding-bottom: calc(100% / {value});')}
    }

    &::after {
        display: table;
        content: '';
        clear: both;
    }

    // This will make sure that the content is positioned properly
    > div {
        width: 100%;
        height: auto;
    }
`;

FlexibleAspectWrapper.propTypes = {
    ratio: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

const FixedAspectWrapper = styled('div')`
    // Style for fixed AspectWrapper
    // This style will set the element to a fixed ratio that can't be changed by its content

    position: relative;

    // ::before the wrapper have a ratio
    &::before {
        display: block;
        content: '';
        width: 100%;
        ${({ ratios }) => responsiveBreakpointsStyle(ratios, 'padding-bottom: calc(100% / {value});')}
    }

    // This will make sure that the content is positioned properly
    > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
`;

FixedAspectWrapper.propTypes = {
    ratio: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

/**
 * AspectWrapper - A wrapper that creates a component with a fixed ratio.
 * @param {node} children - Child components
 * @param {boolean} flexibleHeight - If true, the wrapper can adjust height if content doesn't fit
 * @param {boolean} bypassSupportsCheck - If true, we do not check if browser can handle `aspect-ratio`, and will use the "old way"
 * @param {number} ratio - The ratio used by the wrapper
 * @param {object} rest - Spread remaining props to wrapper, used to add class, styles etc.
 */

const AspectWrapper = ({ children, ratio, flexibleHeight = false, bypassSupportsCheck = false, ...rest }) => {
    // If the browser support the css property 'aspect-ratio' just return a div with desired ratios
    if (!bypassSupportsCheck && typeof window !== 'undefined' && CSS.supports('aspect-ratio', '1 / 1')) {
        // TODO: handle 'flexibleHeight' in DefaultAspectWrapper
        return (
            <DefaultAspectWrapper ratios={ratio} {...rest}>
                {children}
            </DefaultAspectWrapper>
        );
    }

    /* However, if the browser doesn't support css property we'll need a workaround
     * @see https://caniuse.com/?search=aspect-ratio
     */

    // FlexibleAspectWrapper will allow the AspectWrapper to adjust its height if the content doesn't fit
    return flexibleHeight ? (
        <FlexibleAspectWrapper ratios={ratio} {...rest}>
            {/* Use a div to make sure that the content fills its parent correctly */}
            <div>{children}</div>
        </FlexibleAspectWrapper>
    ) : (
        // FixedAspectWrapper always have the same ratio, even if the content doesn't fit
        <FixedAspectWrapper ratios={ratio} {...rest}>
            {/* Use a div to make sure that the content fills its parent correctly */}
            <div>{children}</div>
        </FixedAspectWrapper>
    );
};

AspectWrapper.propTypes = {
    bypassSupportsCheck: PropTypes.bool,
    children: PropTypes.node.isRequired,
    flexibleHeight: PropTypes.bool,
    ratio: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    ]).isRequired,
};

export default AspectWrapper;
