import React from 'react';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import AspectWrapper from '@sportson/core-web/components/AspectWrapper';
import { Newsletter as NewsletterBlock, type WordpressNewsletterBlock } from 'libs/wordpress/content/Blocks/Newsletter';
import { type WordpressStandardModuleSettings } from 'libs/wordpress/types/contentSystem/groups';
import { transformBackgroundColor } from 'libs/wordpress/utils/transformBackground';

export interface NewsletterModuleProps extends Record<string, unknown> {
    data: {
        data: WordpressNewsletterBlock;
        moduleSettings: WordpressStandardModuleSettings;
    };
}

const NewsletterModule = ({ data, ...rest }: NewsletterModuleProps) => {
    const { moduleSettings, data: blockData } = data;
    const { backgroundColor, contentRatio } = moduleSettings;
    const moduleBgColor = transformBackgroundColor(backgroundColor).backgroundColor;

    return (
        <FullWidth
            marginBottom="var(--module-margin-bottom-small)"
            backgroundColor={moduleBgColor || 'var(--color-transparent)'}
        >
            <AspectWrapper ratio={contentRatio}>
                <NewsletterBlock data={blockData} {...rest} />
            </AspectWrapper>
        </FullWidth>
    );
};

export default NewsletterModule;
