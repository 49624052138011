import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { type WordpressHeading, type WordpressTag } from 'libs/wordpress/types/contentSystem/elements';
import {
    type WordpressBackgroundSelection,
    type WordpressNewsletterForm,
} from 'libs/wordpress/types/contentSystem/groups';
import {
    type WordpressHorizontalAlignment,
    type WordpressTextColor,
} from 'libs/wordpress/types/contentSystem/settings';
import { transformBackgroundGroup } from 'libs/wordpress/utils/transformBackground';
import { transformHeading } from 'libs/wordpress/utils/transformHeading';
import transformTextColor from 'libs/wordpress/utils/transformTextColor';
import {
    NewsletterComponent,
    type NewsletterComponentAlignmentProps,
    type NewsletterComponentProps,
} from 'content/Newsletter';

export interface WordpressNewsletterBlock {
    contentHorizontalAlignment: WordpressHorizontalAlignment;
    heading: WordpressHeading;
    newsletterForm: WordpressNewsletterForm;
    tag: WordpressTag;
    textColor?: WordpressTextColor;
    background: WordpressBackgroundSelection;
}

export interface NewsletterBlockProps extends Record<string, unknown> {
    data: WordpressNewsletterBlock;
    layout?: string;
}

const hAlignmentMapper: Record<WordpressHorizontalAlignment, NewsletterComponentAlignmentProps | undefined> = {
    default: undefined,
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
};

export const Newsletter = ({ data }: NewsletterBlockProps) => {
    const isMobile = useBreakpoint({ to: 'tablet.lg' });
    const device = isMobile ? 'mobile' : 'desktop';

    const componentData: NewsletterComponentProps = {
        contentHorizontalAlignment: hAlignmentMapper[data?.contentHorizontalAlignment],
        tag: data?.tag,
        newsletterForm: data?.newsletterForm,
        heading: data?.heading && transformHeading(data?.heading),
        textColor: data?.textColor && transformTextColor(data?.textColor),
        isMobile,
    };

    // Background
    if (data.background[device]) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    return <NewsletterComponent {...componentData} />;
};
